import { DataRange, WidgetDataEntity, WidgetDefinitionWithId } from 'types/inspection-types/WidgetDefinition';

export default function equallyScaleCompatibleWidgets(widgets: WidgetDefinitionWithId[]): WidgetDefinitionWithId[] {
    const metaWidgets: WidgetDefinitionWithId[] = [];
    const types = {} as Record<string, WidgetDefinitionWithId[]>;

    widgets.forEach((w) => {
        const typeHash = `${w.tool.id}`;

        if (!types.hasOwnProperty(typeHash)) {
            types[typeHash] = [];
        }

        types[typeHash].push(w);
    });

    Object.values(types).forEach((compatibleGroup) => {
        const entitiesCombined = compatibleGroup.reduce(
            (acc, w) => [...acc, ...w.dataEntities],
            [] as WidgetDataEntity[],
        );
        const entityRanges = {} as Record<string, DataRange>;

        entitiesCombined.forEach((entity) => {
            const fistRow = entity.data[0];
            const attributes = Object.keys(fistRow);

            attributes.forEach((attr) => {
                entityRanges[attr] = {
                    min: Math.min(
                        ...[
                            entityRanges[attr]?.min ?? Number.POSITIVE_INFINITY,
                            ...entity.data.map((d) => d[attr] as number),
                        ],
                    ),
                    max: Math.max(
                        ...[
                            entityRanges[attr]?.max ?? Number.NEGATIVE_INFINITY,
                            ...entity.data.map((d) => d[attr] as number),
                        ],
                    ),
                };
            });
        });

        // Assign the common ranges to each widget definition (create a copy to not modify the object in the context)
        metaWidgets.push(
            ...compatibleGroup.map((wd) => ({
                ...wd,
                entityRanges: entityRanges,
            })),
        );
    });

    return metaWidgets;
}
