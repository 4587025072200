import React, { useCallback, useMemo } from 'react';
import FilterContext from 'App/FilterContext';
import { EntityType } from 'types/inspection-types/EntityType';
import { produce } from 'immer';
import { Model } from 'types/nn-types/Model';
import { ModelGraphNode, Structure } from 'types/nn-types/ModelGraph';
import { LayerGraphNode } from 'types/nn-types/LayerGraph';
import getEnclosedFilterTypes from 'tools/get-enclosed-filter-types';
import { arrayIntersection } from 'tools/helpers';

const FilterContextProvider = ({ children }: { children: React.ReactNode }) => {
    // Lift the value into the parent’s state: https://reactjs.org/docs/context.html#caveats
    const [filters, setFilters] = React.useState<EntityType[]>([]);

    const addFilter = (entityType: EntityType) => {
        // Pass function to setState, forcing atomic operation to prevent race condition. See: https://stackoverflow.com/a/30341560
        setFilters((prevFilters) =>
            produce(prevFilters, (draftFilters) => {
                const filterSet = new Set(draftFilters);
                filterSet.add(entityType);

                return [...filterSet];
            }),
        );
    };

    const clearFilter = (entityType: EntityType) => {
        // Pass function to setState, forcing atomic operation to prevent race condition. See: https://stackoverflow.com/a/30341560
        setFilters((prevFilters) =>
            produce(prevFilters, (draftFilters) => {
                const filterSet = new Set(draftFilters);
                filterSet.delete(entityType);

                return [...filterSet];
            }),
        );
    };

    const clearAllFilters = () => {
        setFilters([]);
    };

    const containsFilteredElements = (entity: Model | ModelGraphNode | LayerGraphNode): boolean => {
        // const t0 = performance.now();
        const enclosedTypes = getEnclosedFilterTypes(entity);
        // const t1 = performance.now();
        // console.log("Duration: ",  t1 - t0, "ms")
        return arrayIntersection(enclosedTypes, filters).length > 0;
    };

    const getEntityTypesMatchedByFilters = (
        entity: Model | ModelGraphNode | LayerGraphNode,
        recursive = true,
    ): EntityType[] => {
        const enclosedTypes = getEnclosedFilterTypes(entity, recursive);

        return arrayIntersection(enclosedTypes, filters);
    };

    const getStructuresMatchedByFilters = (model: Model): Structure[] => {
        const enclosedTypes = getEnclosedFilterTypes(model, true);

        return arrayIntersection(
            enclosedTypes,
            filters.filter(
                (filter) =>
                    filter === EntityType.STRUCTURE_MULTI_BRANCH ||
                    filter === EntityType.STRUCTURE_SKIP_CONNECTION ||
                    filter === EntityType.STRUCTURE_STREAMLINE,
            ),
        );
    };

    // Memoize the callbacks, so they do not lead to unnecessary re-renders.
    const addFilterMemo = useCallback(addFilter, []);
    const clearFilterMemo = useCallback(clearFilter, []);
    const clearAllFiltersMemo = useCallback(clearAllFilters, []);
    const containsFilteredElementsMemo = useCallback(containsFilteredElements, [filters]);
    const getEntityTypesMatchedByFiltersMemo = useCallback(getEntityTypesMatchedByFilters, [filters]);
    const getStructuresMatchedByFiltersMemo = useCallback(getStructuresMatchedByFilters, [filters]);

    // Memoize the value object itself, so it doesn't lead to unnecessary re-renders.
    const providerValueMemo = useMemo(
        () => ({
            filters,
            addFilter: addFilterMemo,
            clearFilter: clearFilterMemo,
            clearAllFilters: clearAllFiltersMemo,
            containsFilteredElements: containsFilteredElementsMemo,
            getEntityTypesMatchedByFilters: getEntityTypesMatchedByFiltersMemo,
            getStructuresMatchedByFilters: getStructuresMatchedByFiltersMemo,
        }),
        [
            addFilterMemo,
            clearAllFiltersMemo,
            clearFilterMemo,
            containsFilteredElementsMemo,
            filters,
            getEntityTypesMatchedByFiltersMemo,
            getStructuresMatchedByFiltersMemo,
        ],
    );

    return <FilterContext.Provider value={providerValueMemo}>{children}</FilterContext.Provider>;
};

export default FilterContextProvider;
