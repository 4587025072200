import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Card } from 'react-bootstrap';
import usePortal from 'react-useportal';
import { DataArray, isNumber } from 'types/inspection-types/DataArray';
import { getNodeColorScale } from 'App/InspectionPanel/L1LayerUnitComponent/colorScales';
import Wrapper from 'App/InspectionPanel/Wrapper';
import { BsTable } from 'react-icons/bs';
import CardFlap, { Side } from 'App/CardFlap';
import { AxisLeft, AxisTop, TickRendererProps } from '@visx/axis';
import { Text } from '@visx/text';
import { scaleBand } from '@visx/scale';
import _ from 'lodash';
import styled from 'styled-components';

const ClickableText = styled(Text)`
    cursor: pointer;
`;

interface Props {
    marginTop: number;
    visWidth: number;
    visHeight: number;
    classes: Array<number | string>;
    data: DataArray;
}

const VIS_MARGINS = { top: 30, right: 10, bottom: 10, left: 40 };

const NeuronClassPixelVis: React.FunctionComponent<Props> = ({
    marginTop,
    visWidth,
    visHeight,
    classes,
    data,
}: Props) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
    const [marginRight, setMarginRight] = useState<number>(0);
    const { Portal } = usePortal({
        bindTo: document?.getElementById('inspection-panel-container') ?? undefined,
    });
    const [sortByClass, setSortByClass] = useState<string | undefined>();

    // Sort by clicked class, if any.
    if (sortByClass) {
        // The sortByClass variable is always a formatted string. Find the original key, which is either
        // a string or a number.
        const classKey = classes.find((c) => `${c}` === sortByClass);

        if (classKey !== undefined) {
            data.sort((rowA, rowB) => {
                const valueA: number = isNumber(rowA[classKey]) ? (rowA[classKey] as number) : 0;
                const valueB: number = isNumber(rowB[classKey]) ? (rowB[classKey] as number) : 0;

                return valueB - valueA;
            });
        }
    }

    const dataReduce = useCallback(
        (callback: (acc: number, curr: number) => number, initialValue: number) =>
            data.reduce<number>((accOuter: number, currRow) => {
                const rowMin = classes.reduce<number>((accInner, currClass) => {
                    return callback(accInner, currRow[currClass] as number);
                }, initialValue);
                return callback(accOuter, rowMin);
            }, initialValue),
        [classes, data],
    );

    const minValue = dataReduce(Math.min, Number.POSITIVE_INFINITY);
    const maxValue = dataReduce(Math.max, Number.NEGATIVE_INFINITY);
    const colorScale = useMemo(() => getNodeColorScale(minValue, maxValue), [maxValue, minValue]);

    const scaleX = useMemo(
        () =>
            scaleBand<number>({
                range: [VIS_MARGINS.left, visWidth - VIS_MARGINS.right],
                domain: _.range(classes.length),
            }),
        [classes.length, visWidth],
    );

    const scaleY = useMemo(
        () =>
            scaleBand<number>({
                range: [VIS_MARGINS.top, visHeight - VIS_MARGINS.bottom],
                domain: _.range(data.length),
            }),
        [data.length, visHeight],
    );

    const tickFormatterX = (v: number) => `${classes[v]}`;
    const tickFormatterY = (v: number) => `${data[v]['id']}`;

    const dx = scaleX.step();
    const dy = scaleY.step();

    const tickComponent = useCallback(
        ({ formattedValue, ...props }: TickRendererProps) => (
            <ClickableText {...props} onClick={() => setSortByClass(formattedValue)}>
                {formattedValue}
            </ClickableText>
        ),
        [],
    );

    useEffect(() => setMarginRight(isCollapsed ? -(visWidth + 40) : 0), [isCollapsed, visWidth]);

    return (
        <Portal>
            <Wrapper $marginRight={marginRight} $marginTop={marginTop}>
                <CardFlap
                    icon={<BsTable style={{ marginLeft: 10 }} />}
                    isCardCollapsed={isCollapsed}
                    setIsCardCollapsed={setIsCollapsed}
                    side={Side.LEFT}
                />
                <Card style={{ boxShadow: '0 0 10px var(--gray)' }}>
                    <Card.Header>Neurons &#10799; Classes</Card.Header>
                    <Card.Body>
                        <svg width={visWidth} height={visHeight}>
                            <AxisTop
                                tickFormat={tickFormatterX}
                                top={VIS_MARGINS.top}
                                scale={scaleX}
                                tickLength={0}
                                hideTicks={true}
                                hideAxisLine={true}
                                label={'Class'}
                                labelOffset={10}
                                tickComponent={tickComponent}
                            />
                            <AxisLeft
                                tickFormat={tickFormatterY}
                                left={VIS_MARGINS.left}
                                scale={scaleY}
                                tickLength={4}
                                hideTicks={true}
                                hideAxisLine={true}
                                label={'Neuron Index'}
                                numTicks={30}
                                labelOffset={24}
                            />

                            {classes.map((c, idx) => {
                                const x = scaleX(idx);

                                return (
                                    <g key={c}>
                                        {data.map((d, idy) => {
                                            const y = scaleY(idy);
                                            return (
                                                <rect
                                                    fill={colorScale(d[c] as number)}
                                                    x={x}
                                                    y={y}
                                                    width={dx}
                                                    height={dy}
                                                    key={`${c}_${idy}`}
                                                />
                                            );
                                        })}
                                    </g>
                                );
                            })}
                        </svg>
                    </Card.Body>
                </Card>
            </Wrapper>
        </Portal>
    );
};

export default NeuronClassPixelVis;
