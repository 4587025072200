import React, { Fragment, useContext } from 'react';
import { WidgetProps } from 'App/WidgetPanel/Widget';
import { isDate, isMatrix, isNumber, isNumberArray } from 'types/inspection-types/DataArray';
import GroupsContext from 'App/GroupsContext';
import _ from 'lodash';
import { Model } from 'types/nn-types/Model';
import styled from 'styled-components';

const ScrollableDiv = styled.div`
    overflow-x: auto;
    overflow-y: auto;
`;

const StyledTable = styled.table`
    font-size: 10pt;

    & tr {
        border-bottom: 1px solid var(--gray);

        & td {
            padding-right: 5px;

            &:last-child {
                padding-right: 5px;
            }
        }

        &:last-child {
            border-bottom: none;
        }
    }
`;

const VerbalizationWidget: React.FunctionComponent<WidgetProps> = (props: WidgetProps) => {
    const { groups } = useContext(GroupsContext);
    const models = _.flatten(groups.map((g) => g.models));

    const { data } = props.widgetDefinition.dataEntities[0];

    return (
        <ScrollableDiv>
            <StyledTable>
                <tbody>
                    {data.map((d) => {
                        const value = d['value'] ?? '';

                        let formattedValue;
                        if (isDate(value)) formattedValue = value.toLocaleString();
                        else if (isNumber(value)) formattedValue = value % 1 !== 0 ? value.toPrecision(2) : value;
                        else if (isNumberArray(value))
                            formattedValue = '[' + value.map((v) => v.toString()).join(', ') + ']';
                        else if (isMatrix(value)) formattedValue = value.toString();
                        else formattedValue = value;

                        const model: Model | null = d['modelId']
                            ? models.find((m) => m.id === d['modelId']) ?? null
                            : null;

                        return (
                            <tr key={d['label'] as string}>
                                <td>{(d['label'] as string) ?? ''}</td>
                                <td>
                                    <b>{formattedValue}</b>
                                    {model && (
                                        <>
                                            <br />[{model.name}]
                                        </>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </StyledTable>
        </ScrollableDiv>
    );
};

export default VerbalizationWidget;
