import React, { useState } from 'react';
import { NeuronConnectionId } from 'types/inspection-types/NeuronConnection';
import NeuronSelectionContext from 'App/InspectionPanel/L1LayerUnitComponent/NeuronSelectionContext';
import { interpolateLab } from 'd3-interpolate';
import { useBrush, useLink } from 'tools/hooks/useLinkAndBrush';

interface Props {
    fromId: string;
    toId: string;
    points: { x: number; y: number }[];
    color: string;
}

const LinkComponent: React.FunctionComponent<Props> = ({ fromId, toId, points, color }: Props) => {
    const { selectedNeurons, isNeuronSelected } = React.useContext(NeuronSelectionContext);

    const [hovered, setHovered] = useState<boolean>(false);

    useBrush<NeuronConnectionId | undefined>('weight-hovered', hovered ? { fromId, toId } : undefined);
    const [isLinked] = useLink<string | undefined>(
        'neuron-hovered',
        (neuronId) => fromId === neuronId || toId === neuronId,
    );

    const dx = points[1].x - points[0].x;
    const dy = points[1].y - points[0].y;
    const d = `m ${points[0].x},${points[0].y} c ${dx / 2},${0} ${dx / 2},${dy} ${dx},${dy}`;

    let stroke = color;

    if (selectedNeurons.length > 0 && !(isNeuronSelected(fromId) || isNeuronSelected(toId))) {
        stroke = interpolateLab(stroke, 'white')(0.8);
    }
    if (hovered || isLinked) {
        stroke = '#5C996B';
    }

    return (
        <g onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            <path
                style={{
                    stroke,
                    strokeWidth: 1,
                    fill: 'none',
                }}
                d={d}
            />
        </g>
    );
};

export default LinkComponent;
