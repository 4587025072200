import { LayerwiseStatisticalDescriptors, StatisticalDescriptors } from 'types/nn-types/StatisticalDescriptors';

export type InterestingnessSupercategory = keyof LayerwiseStatisticalDescriptors;
export type InterestingnessSubcategory = keyof StatisticalDescriptors;

export class InterestingnessDescriptor {
    constructor(
        public readonly supercategory: InterestingnessSupercategory,
        public readonly subcategory: InterestingnessSubcategory,
        public readonly value: number,
    ) {}

    public static fromStatisticalDescriptors(
        statisticalDescriptors: StatisticalDescriptors | undefined,
        supercategory: InterestingnessSupercategory,
    ): InterestingnessDescriptor[] {
        if (statisticalDescriptors !== undefined) {
            return Object.entries(statisticalDescriptors).map(
                ([key, value]) =>
                    new InterestingnessDescriptor(supercategory, key as InterestingnessSubcategory, value),
            );
        }
        return [];
    }
}
