import React from 'react';
import { Matrix } from 'mathjs';
import { getLastCheckpointStep, matrixToBase64Image } from 'tools/helpers';
import BackendQueryEngine from 'tools/BackendQueryEngine';
import { isMatrix } from 'types/inspection-types/DataArray';
import { Model } from 'types/nn-types/Model';
import { useTooltipInPortal } from '@visx/tooltip';
import { Circle } from '@visx/shape';
import { Point } from '@visx/zoom/lib/types';
import { useBrush, useLink } from 'tools/hooks/useLinkAndBrush';

interface Props {
    index: number;
    x: number;
    y: number;
    color: string;
    model?: Model;
    highlight: boolean;
}

const DataPoint: React.FunctionComponent<Props> = ({ index, x, y, color, highlight, model }: Props) => {
    const [hoverPos, setHoverPos] = React.useState<Point | undefined>();
    const [correspondingDataSample, setCorrespondingDataSample] = React.useState<undefined | Matrix>();

    useBrush<number | undefined>('data-sample-hovered', hoverPos ? index : undefined);
    const [isLinked] = useLink<number | undefined>('data-sample-hovered', index);

    const { TooltipInPortal } = useTooltipInPortal();

    const fillOpacity = highlight || isLinked ? 1 : 0.5;

    React.useEffect(() => {
        let isRequestStillValid = true;

        if (hoverPos !== undefined && model !== undefined) {
            BackendQueryEngine.getSamplesAndActivations(
                model.id, // TODO: Get this information from WidgetDefinition
                getLastCheckpointStep(model), // TODO: Get this information from WidgetDefinition
                ['index', 'x', 'y_label'],
                [
                    {
                        type: 'filter',
                        query: 'index == ' + index,
                    },
                ],
            ).then((d) => {
                isRequestStillValid && isMatrix(d[0]['x']) && setCorrespondingDataSample(d[0]['x']);
            });
        } else {
            setCorrespondingDataSample(undefined);
        }

        return () => {
            isRequestStillValid = false;
        };
    }, [hoverPos, index, model]);

    return (
        <>
            <Circle
                onMouseOver={(e: React.MouseEvent<SVGCircleElement>) => setHoverPos({ x: e.pageX, y: e.pageY })}
                onMouseLeave={() => setHoverPos(undefined)}
                cx={x}
                cy={y}
                r={isLinked ? 5 : 2}
                fill={color}
                fillOpacity={fillOpacity}
            />
            {correspondingDataSample && (
                <TooltipInPortal
                    // set this to random so it correctly updates with parent bounds
                    key={Math.random()}
                    top={hoverPos?.y}
                    left={hoverPos?.x}
                >
                    <svg width={correspondingDataSample.size()[0]} height={correspondingDataSample.size()[1]}>
                        <image x={0} y={0} href={matrixToBase64Image(correspondingDataSample)} />
                    </svg>
                </TooltipInPortal>
            )}
        </>
    );
};
export default React.memo(DataPoint);
