import { WidgetType } from 'types/inspection-types/WidgetType';
import { Tool } from 'types/inspection-types/Tool';
import { DataArray } from 'types/inspection-types/DataArray';
import { Entity } from 'types/inspection-types/Entity';
import { PickScaleConfigWithoutType } from '@visx/scale/lib/types/ScaleConfig';
import createLinearScale from '@visx/scale/lib/scales/linear';
import { AxisScale } from '@visx/axis';

export interface WidgetDataEntity {
    entity: Entity;
    entityName: string;
    color: string;
    data: DataArray;
    unit?: string;
}

export type PromisedWidgetDataEntity = Omit<WidgetDataEntity, 'data'> & { data: DataArray | Promise<DataArray> };

export interface DataRange {
    min: number;
    max: number;
}

export interface WidgetDefinition {
    widgetType: WidgetType;
    tool: Tool;
    targetEntity: Entity;
    dataEntities: WidgetDataEntity[];
    entityRanges?: Record<string, DataRange>; // This is used to override the scales taken from the data entities (to enable common scales across entities)
    scaleCreatorFn: (config?: PickScaleConfigWithoutType<'linear', number>) => AxisScale<number>;
    // levelsOfAbstraction: LevelOfAbstraction[];
    pinned: boolean;
    // priority: number;
    ready: boolean; // Indicates, if the data for this widget is fully loaded (all promises resolved)
}

type IPromisedWidgetDefinition = Omit<WidgetDefinition, 'dataEntities'> & { entities: PromisedWidgetDataEntity[] };

export class PromisedWidgetDefinition implements IPromisedWidgetDefinition {
    constructor(
        public widgetType: IPromisedWidgetDefinition['widgetType'],
        public tool: IPromisedWidgetDefinition['tool'],
        public targetEntity: IPromisedWidgetDefinition['targetEntity'],
        // public levelsOfAbstraction: IPromisedWidgetDefinition['levelsOfAbstraction'],
        public entities: IPromisedWidgetDefinition['entities'],
        public scaleCreatorFn: IPromisedWidgetDefinition['scaleCreatorFn'] = createLinearScale,
        public pinned: IPromisedWidgetDefinition['pinned'] = false,
        public ready = false,
    ) {}
}

export interface WidgetDefinitionWithId extends WidgetDefinition {
    widgetId: string;
}
