import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootswatch/dist/cosmo/bootstrap.css';
import 'styles/custom.scss';
import 'react-toastify/dist/ReactToastify.css';
import App from 'App/App';

// Enable `why-did-you-render` in dev mode.
if (process.env.NODE_ENV === 'development') {
    console.info('Development mode detected; enabling @welldone-software/why-did-you-render.');
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
        trackAllPureComponents: true,
    });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    // <React.StrictMode>
    <App />,
    // </React.StrictMode>,
);
