import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import InspectionPanelContext from 'App/InspectionPanel/InspectionPanelContext';
import { LevelOfAbstraction } from 'types/inspection-types/LevelOfAbstraction';
import { Model } from 'types/nn-types/Model';
import tinyColor from 'tinycolor2';
import _ from 'lodash';
import GroupsContext from 'App/GroupsContext';

const Wrapper = styled.div`
    left: 40px;
    position: absolute;
    top: 5px;

    ol.breadcrumb {
        background: transparent;
        padding-left: 0;
    }
`;

const getLayerName = (models: Model[], layerID: string): string | undefined => {
    let layerName = undefined;

    models.forEach((model) => {
        model.graph.nodes.forEach((node) => {
            if (node.id === layerID) layerName = node.name;
        });
    });

    return layerName;
};

const getModel = (models: Model[], modelID: string): Model | undefined => {
    let model = undefined;

    models.forEach((modelToFind) => {
        if (modelToFind.id === modelID) model = modelToFind;
    });

    return model;
};

interface Props {}

const Navigation: React.FunctionComponent<Props> = (props: Props) => {
    const { groups } = useContext(GroupsContext);
    const models = _.flatten(groups.map((g) => g.models));

    const levelsOfAbstraction = Object.values(LevelOfAbstraction)
        .map((levelOfAbstraction) => Number(levelOfAbstraction))
        .filter((levelOfAbstraction) => !isNaN(levelOfAbstraction));
    const { ascendLofa, hierarchyPathEntityIDs, lofa } = useContext(InspectionPanelContext);

    return (
        <Wrapper>
            <Breadcrumb>
                {levelsOfAbstraction.map((levelOfAbstraction) => {
                    if (lofa > levelOfAbstraction) return null;

                    const isActive = lofa === levelOfAbstraction;
                    let style = {};
                    let title = `L${Number(levelOfAbstraction)}`;

                    if (levelOfAbstraction === LevelOfAbstraction.SINGLE_MODEL) {
                        const modelID = hierarchyPathEntityIDs[LevelOfAbstraction.SINGLE_MODEL - levelOfAbstraction];
                        const model = getModel(models, modelID);

                        if (model) title += ` (${model.name})`;
                    } else if (levelOfAbstraction === LevelOfAbstraction.LAYERS_UNITS) {
                        const layerID = hierarchyPathEntityIDs[LevelOfAbstraction.SINGLE_MODEL - levelOfAbstraction];
                        const layerName = getLayerName(models, layerID);

                        if (layerName) title += ` (${layerName})`;
                    }

                    if (levelOfAbstraction <= LevelOfAbstraction.SINGLE_MODEL) {
                        const modelID = hierarchyPathEntityIDs[0];
                        const model = getModel(models, modelID);

                        if (model) {
                            const transparencyMultiplier = LevelOfAbstraction.SINGLE_MODEL - levelOfAbstraction + 1;
                            const backgroundColor = tinyColor(model.preferences.baseColor).setAlpha(
                                0.5 - 0.15 * transparencyMultiplier,
                            );
                            const fontColor = tinyColor(model.preferences.baseColor).darken(20);

                            style = {
                                backgroundColor: backgroundColor.toRgbString(),
                                borderRadius: '0.25em',
                                color: fontColor,
                                padding: '0 0.25em',
                            };
                        }
                    }

                    return (
                        <BreadcrumbItem
                            active={isActive}
                            key={levelOfAbstraction}
                            linkAs={'div'}
                            linkProps={{
                                style: {
                                    ...style,
                                    cursor: isActive ? 'default' : 'pointer',
                                },
                            }}
                            onClick={() => ascendLofa(levelOfAbstraction - lofa)}
                            style={{ color: 'grey', cursor: 'default', userSelect: 'none' }}
                        >
                            <span style={isActive ? style : undefined}>{title}</span>
                        </BreadcrumbItem>
                    );
                })}
            </Breadcrumb>
        </Wrapper>
    );
};

export default Navigation;
