import * as React from 'react';
import { useContext, useMemo, useState } from 'react';
import ArchitectureGraphComponent from 'App/InspectionPanel/L3TreeComponent/ModelComponent/ArchitectureGraphComponent';
import { layout } from 'App/InspectionPanel/L3TreeComponent/ModelComponent/layout';
import styled from 'styled-components';
import InspectionPanelContext from 'App/InspectionPanel/InspectionPanelContext';
import WidgetContext from 'App/WidgetContext';
import SelectionContext from 'App/SelectionContext';
import ToolContext from 'App/ToolContext';
import addWidget from 'App/InspectionPanel/L3TreeComponent/ModelComponent/add-widget';
import MetadataInfoButton from 'App/InspectionPanel/L3TreeComponent/ModelComponent/MetadataInfoButton';
import FilterBadges from 'App/InspectionPanel/FilterBadges';
import ModelBoundingBox from 'App/InspectionPanel/L3TreeComponent/ModelComponent/ModelBoundingBox';
import { isCommandKeyPressedOnMacOS } from 'tools/helpers';
import ModelContext from 'App/ModelContext';
import ModelInfoLabel from 'App/InspectionPanel/L3TreeComponent/ModelComponent/ModelInfoLabel';
import { StyledModelLabel } from 'App/InspectionPanel/L3TreeComponent/ModelComponent/styled';
import { mid } from 'tools/colors';

const isPositiveNumber = (value: number) => value >= 0 && value < Infinity;

const ModelNameLabel = styled(StyledModelLabel)`
    font-weight: bold;
    dominant-baseline: hanging;
`;

interface Props {
    x: number;
    y: number;
    horizontalPadding?: number;
    verticalPadding?: number;
    nodesep?: number;
    onSizeChange?: (newWidth: number, newHeight: number) => void;
}

interface WidgetBadgesProps {
    modelId: string;
    color: string;
    right: number;
    top: number;
}

const WidgetBadges = ({ modelId, color, right, top }: WidgetBadgesProps) => {
    const { getAssociatedWidgets } = React.useContext(WidgetContext);

    const associatedWidgets = getAssociatedWidgets(modelId).filter((w) => w.dataEntities.length === 1);

    return (
        <g style={{ color }}>
            {associatedWidgets.map((w, idx) =>
                React.cloneElement(w.tool.icon, { x: right - 25 * idx, y: top, key: w.widgetId }),
            )}
        </g>
    );
};

const ModelComponent: React.FunctionComponent<Props> = ({
    onSizeChange,
    horizontalPadding = 20,
    verticalPadding = 50,
    nodesep = 20,
    ...props
}: Props) => {
    const { model } = useContext(ModelContext);
    const { toggleSelection } = React.useContext(SelectionContext);
    const { descendLofa } = React.useContext(InspectionPanelContext);
    const { addWidget: addWidgetCb } = React.useContext(WidgetContext);
    const { activeTool } = React.useContext(ToolContext);
    const [hovered, setHovered] = useState<boolean>(false);

    const dagreArchitectureGraph = useMemo(
        () => layout(model, nodesep, horizontalPadding, verticalPadding),
        [model, nodesep, horizontalPadding, verticalPadding],
    );

    const width = dagreArchitectureGraph ? (dagreArchitectureGraph.graph().width as number) : 0;
    const height = dagreArchitectureGraph ? (dagreArchitectureGraph.graph().height as number) : 0;

    // componentDidMount
    React.useEffect(() => {
        // Notify parent about size re-calculation
        if (onSizeChange && (dagreArchitectureGraph.graph().width ?? Infinity) < Infinity) {
            const width = dagreArchitectureGraph.graph().width ?? NaN;
            const height = dagreArchitectureGraph.graph().width ?? NaN;

            if (isPositiveNumber(width) && isPositiveNumber(height)) {
                onSizeChange(
                    dagreArchitectureGraph.graph().width as number,
                    dagreArchitectureGraph.graph().height as number,
                );
            }
        }
    }, [dagreArchitectureGraph, onSizeChange]);

    const onClickHandler = (e: React.MouseEvent) => {
        if (e.button === 0) {
            if (e.ctrlKey || isCommandKeyPressedOnMacOS(e)) {
                toggleSelection(model);
            } else if (activeTool?.isApplicable(model.type)) {
                addWidget(model, activeTool, addWidgetCb);
            }
        }

        e.stopPropagation();
    };

    const onDoubleClickHandler = (e: React.MouseEvent) => {
        if (e.button === 0) {
            if (!e.ctrlKey && !isCommandKeyPressedOnMacOS(e) && !activeTool) {
                descendLofa(model.id);
            }
        }

        e.stopPropagation();
    };

    return (
        <g
            onClick={onClickHandler}
            onDoubleClick={onDoubleClickHandler}
            transform={`translate(${props.x - width / 2} ${props.y - height / 2})`}
            onMouseOver={() => setHovered(true)}
            onMouseOut={() => setHovered(false)}
        >
            <ModelBoundingBox
                id={`l3-model-component-${model.index}`}
                width={width}
                height={height}
                hovered={hovered}
            />
            {dagreArchitectureGraph && <ArchitectureGraphComponent dagreArchitectureGraph={dagreArchitectureGraph} />}
            {dagreArchitectureGraph && <FilterBadges dagreArchitectureGraph={dagreArchitectureGraph} />}
            <ModelNameLabel x={8} y={6}>
                {model.info.label}
            </ModelNameLabel>
            <ModelInfoLabel x={8} y={height - 6} model={model} />
            <WidgetBadges modelId={model.id} color={mid(model.preferences.baseColor)} right={width - 25} top={6} />
            <MetadataInfoButton x={width - 30} y={height - 30} iconSize={20} />
        </g>
    );
};

export default ModelComponent;
