import React, { useState } from 'react';
import dagre from 'dagre';
import styled from 'styled-components';
import WidgetContext from 'App/WidgetContext';
import _ from 'lodash';
import { polygonHull } from 'd3-polygon';
import Shape, { Point } from '@doodle3d/clipper-js';
import { curveLinearClosed, line } from 'd3-shape';
import ToolContext from 'App/ToolContext';
import * as uuid from 'uuid';
import { lighter } from 'tools/colors';
import { Entity } from 'types/inspection-types/Entity';
import ApplicabilityHint from 'App/InspectionPanel/ApplicabilityHint';
import { mean } from 'd3-array';
import { useBrush, useLink } from 'tools/hooks/useLinkAndBrush';
import { closeEquals } from 'tools/helpers';

const LABEL_COLOR = '#818181';

const StyledGroupLabel = styled.text`
    text-anchor: start;
    fill: ${() => LABEL_COLOR};
    font-size: 16px;
    dominant-baseline: hanging;
    font-weight: bold;
`;

const ConvexHullPath = styled.path`
    stroke: #afafaf;
    stroke-dasharray: 5 3;
`;

interface Props {
    entity: Entity;
    graph: dagre.graphlib.Graph;
    onClick: (mouseEvent: React.MouseEvent) => void;
    backgroundColor?: string;
    offset?: number;
}

export function nodeMap<S, T>(dagreGraph: dagre.graphlib.Graph<S>, fn: (node: dagre.Node<S>) => T): T[] {
    return dagreGraph.nodes().map((n: string) => {
        const node = dagreGraph.node(n);
        return fn(node);
    });
}

const ConvexHullComponent = ({ entity, graph, onClick, backgroundColor, offset = 50 }: Props) => {
    const { getAssociatedWidgets } = React.useContext(WidgetContext);
    const { activeTool } = React.useContext(ToolContext);

    const filterIdRef = React.useRef<string>(uuid.v4());

    const [hovered, setHovered] = useState(false);

    const associatedWidgets = getAssociatedWidgets(entity.id);

    useBrush<string | undefined>('entity-hovered', hovered ? entity.id : undefined);
    const [isLinked] = useLink<string | undefined>('widget-hovered', entity.id);

    const pathGenerator = line<Point>()
        // .curve(curveCatmullRomClosed)
        .curve(curveLinearClosed)
        .x((d) => d.X)
        .y((d) => d.Y);

    const cornerPoints: Point[] = _.flatten(
        nodeMap(graph, (node) => {
            return [
                { X: node.x - node.width / 2, Y: node.y - node.height / 2 },
                { X: node.x - node.width / 2, Y: node.y + node.height / 2 },
                { X: node.x + node.width / 2, Y: node.y - node.height / 2 },
                { X: node.x + node.width / 2, Y: node.y + node.height / 2 },
            ];
        }),
    );

    const top = Math.min(...cornerPoints.map((p) => p.Y));

    const right = cornerPoints.reduce((acc, curr) => {
        if (closeEquals(curr.Y, top)) {
            if (curr.X >= acc - 1) {
                return curr.X;
            }
        }
        return acc;
    }, Number.NEGATIVE_INFINITY);

    const left = cornerPoints.reduce((acc, curr) => {
        if (closeEquals(curr.Y, top)) {
            if (curr.X <= acc + 1) {
                return curr.X;
            }
        }
        return acc;
    }, Number.POSITIVE_INFINITY);

    const topRightCorner = { X: right, Y: top };
    const topLeftCorner = { X: left, Y: top };

    const convexHull: Point[] | undefined = polygonHull(cornerPoints.map((p) => [p.X, p.Y]))?.map(([X, Y]) => ({
        X,
        Y,
    }));

    if (convexHull) {
        let convexHullShape = new Shape([convexHull], true);

        convexHullShape = convexHullShape.offset(offset, {
            jointType: 'jtSquare',
        });

        if (convexHullShape.paths[0]) {
            // Get the points of the convex hull.
            const convexHullPoints = convexHullShape.paths[0];

            // Construct the point that lies in the top middle of the convex hull.
            // It is needed for the ApplicabilityHint.
            const convexHullMaxY = Math.min(...convexHullPoints.map((p) => p.Y));
            const convexHullTopPoints = convexHullPoints.filter((p) => Math.abs(convexHullMaxY - p.Y) < 1);
            const convexHullTopCenterPoint = {
                X: mean(convexHullTopPoints.map((p) => p.X)) ?? 0,
                Y: mean(convexHullTopPoints.map((p) => p.Y)) ?? 0,
            };

            return (
                <g onClick={onClick} onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                    <filter id={filterIdRef.current}>
                        <feDropShadow dx="0" dy="0" stdDeviation="10" floodColor={'#AAA'} />
                    </filter>
                    {activeTool?.isApplicable(entity.type) && (
                        <ApplicabilityHint x={convexHullTopCenterPoint.X} y={convexHullTopCenterPoint.Y} size={32} />
                    )}
                    <ConvexHullPath
                        className={activeTool?.isApplicable(entity.type) ? 'clickable-svg-element' : ''}
                        d={pathGenerator(convexHullPoints) ?? ''}
                        fill={backgroundColor ? lighter(backgroundColor) : '#dcdcdc'}
                        fillOpacity={0.1}
                        style={{ strokeWidth: isLinked ? '3px' : '1px' }}
                    />
                    <StyledGroupLabel x={topLeftCorner.X} y={topLeftCorner.Y + 10 - offset}>
                        {entity.name}
                    </StyledGroupLabel>
                    {associatedWidgets.map((w, idx) =>
                        React.cloneElement(w.tool.icon, {
                            x: topRightCorner.X - 26 * idx - 18,
                            y: topRightCorner.Y + 10 - offset,
                            size: 18,
                            color: LABEL_COLOR,
                            key: w.widgetId,
                        }),
                    )}
                </g>
            );
        }

        return null;
    }

    return null;
};

export default ConvexHullComponent;
