import React from 'react';
import styled from 'styled-components';
import { Card } from 'react-bootstrap';
import ToolButton from 'App/ToolboxPanel/ToolButton';
import InspectionPanelContext from 'App/InspectionPanel/InspectionPanelContext';
import { Tool } from 'types/inspection-types/Tool';

const ScrollContainer = styled.div`
    flex: 0 0 100px;

    display: block;
    position: relative;
    overflow-y: auto;

    box-shadow: 0 0 10px var(--dark);
    z-index: 1000;

    background: var(--light);
`;

const ToolboxPanelContainer = styled.div`
    position: absolute;
    width: fit-content;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    padding: 10px;

    & div {
        border-radius: 0 !important;
        background-color: unset;
        border: none;
    }
`;

const ToolboxCard = styled(Card)`
    margin-bottom: 20px !important;

    border: none;

    &:last-child {
        margin-bottom: 0;
    }
`;

const ToolboxCardHeader = styled(Card.Header)`
    padding: 5px 0;
    max-width: 80px;
    word-wrap: break-word;
`;

const ToolboxCardBody = styled(Card.Body)`
    padding: 0;
    margin: 0;

    // Display the tools in a grid of 2 columns.
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
`;

const ToolboxPanel = () => {
    const { lofa } = React.useContext(InspectionPanelContext);

    const toolGroups = Tool.getToolGroups(lofa);

    return (
        <ScrollContainer>
            <ToolboxPanelContainer className={'no-select'} id={'toolbox-panel'}>
                {Object.entries(toolGroups).map(([group, tools], gidx) => (
                    <div key={group}>
                        <ToolboxCard>
                            <ToolboxCardHeader className={'text-center'}>{group}</ToolboxCardHeader>
                            <ToolboxCardBody className={'text-center'}>
                                {tools.map((tool) => (
                                    <ToolButton key={tool.name} tool={tool} />
                                ))}
                            </ToolboxCardBody>
                        </ToolboxCard>
                        {gidx < Object.entries(toolGroups).length - 1 && <hr />}
                    </div>
                ))}
            </ToolboxPanelContainer>
        </ScrollContainer>
    );
};

export default ToolboxPanel;
