import { Subject } from 'rxjs';
import { DependencyList, useCallback, useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const eventSubjects = new Map<string, Subject<any>>();

export const useLink = <T>(
    eventIdentifier: string,
    linkedEntity: T | ((brushedEntity: T) => boolean),
    deps?: DependencyList,
) => {
    const [isLinked, setIsLinked] = useState<boolean>(false);

    if (!eventSubjects.has(eventIdentifier)) {
        eventSubjects.set(eventIdentifier, new Subject<T>());
    }

    const eventSubject = eventSubjects.get(eventIdentifier) as Subject<T>;

    useEffect(() => {
        const subscription = eventSubject.subscribe((brushedEntity: T) => {
            if (linkedEntity instanceof Function) {
                setIsLinked(linkedEntity(brushedEntity));
            } else {
                setIsLinked(brushedEntity === linkedEntity);
            }
        });

        return () => {
            subscription.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventSubject, ...(deps ?? [])]);

    return [isLinked];
};

export const useBrush = <T>(eventIdentifier: string, brushedEntity: T) => {
    if (!eventSubjects.has(eventIdentifier)) {
        eventSubjects.set(eventIdentifier, new Subject<T>());
    }

    const eventSubject = eventSubjects.get(eventIdentifier) as Subject<T>;

    useEffect(() => {
        // console.log('Updating eventSubject', eventIdentifier);
        eventSubject.next(brushedEntity);
    }, [eventSubject, brushedEntity, eventIdentifier]);

    // Return a function that lets the calling component set the brushedEntity manually.
    return [
        useCallback(
            (brushedEntity: T) => {
                eventSubject.next(brushedEntity);
            },
            [eventSubject],
        ),
    ];
};
