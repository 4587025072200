import { useState } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';

export type UseBBoxRect = Pick<DOMRectReadOnly, 'x' | 'y' | 'top' | 'left' | 'right' | 'bottom' | 'height' | 'width'>;
export type UseMeasureRef<E extends Element = Element> = (element: E | null) => void;
export type UseMeasureResult<E extends Element = Element> = [UseMeasureRef<E>, UseBBoxRect];

const defaultState: UseBBoxRect = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
};

const epsilonEquals = (rect1: UseBBoxRect, rect2: UseBBoxRect, epsilon: number): boolean => {
    return (Object.keys(defaultState) as Array<keyof UseBBoxRect>).every(
        (key) => Math.abs(rect1[key] - rect2[key]) <= epsilon,
    );
};

const useBoundingBox = <E extends Element = Element>(epsilon?: number): UseMeasureResult<E> => {
    const [element, ref] = useState<E | null>(null);
    const [rect, setRect] = useState<UseBBoxRect>(defaultState);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resizeObserver = new (window as any).ResizeObserver(() => {
        if (element) {
            let tmpRect: UseBBoxRect;

            if (element instanceof SVGGraphicsElement) {
                const { height, width, x, y }: DOMRect = element.getBBox();
                tmpRect = { ...defaultState, height, width, x, y };
            } else {
                const { bottom, height, left, right, top, width, x, y } = element.getBoundingClientRect();
                tmpRect = { x, y, width, height, top, left, bottom, right };
            }

            if (epsilon === undefined || !epsilonEquals(rect, tmpRect, epsilon)) {
                setRect(tmpRect);
            }
        }
    });

    useIsomorphicLayoutEffect(() => {
        if (!element) return;
        resizeObserver.observe(element);
        return () => {
            resizeObserver.disconnect();
        };
    }, [element]);

    return [ref, rect];
};

const useBBoxMock: typeof useBoundingBox = () => [
    () => {
        // Empty dummy function
    },
    defaultState,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default !!(window as any).ResizeObserver ? useBoundingBox : useBBoxMock;
