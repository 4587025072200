import { isModel, Model } from 'types/nn-types/Model';
import { Group } from 'types/nn-types/Group';
import cytoscape from 'cytoscape';
import sha256 from 'crypto-js/sha256';
import { EntityType } from 'types/inspection-types/EntityType';

export function constructModelGroups(models: Model[]): Group[] {
    const cytoscapeGraph = cytoscape();
    const modelIds = models.map((m) => m.id);
    modelIds.forEach((mId) => cytoscapeGraph.add({ group: 'nodes', data: { id: mId } }));
    models.forEach((m) => {
        m.info.parents
            .filter((pId) => modelIds.includes(pId))
            .forEach((pId) => {
                cytoscapeGraph.add({ group: 'edges', data: { id: `${pId}_${m.id}`, source: pId, target: m.id } });
            });
    });

    const connectedComponents = cytoscapeGraph.elements().components();

    return connectedComponents.map((c, idx) => {
        const modelIds = c
            .nodes()
            .map((n) => n.id())
            .sort();

        // Calculate unique group ID
        const groupId: string = sha256(modelIds.join('_')).toString();
        const groupModels = modelIds.map((mId) => models.find((m) => m.id === mId)).filter(isModel);

        return {
            id: groupId,
            name: `Group ${idx + 1}`,
            type: EntityType.TREE_OF_MODELS,
            models: groupModels,
            interestingness: [],
        };
    });
}
