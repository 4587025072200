import React, { useCallback, useContext } from 'react';
import ConvexHullComponent from 'App/InspectionPanel/ConvexHullComponent';
import { ModelGraphNode } from 'types/nn-types/ModelGraph';
import dagre from 'dagre';
import { L1VisualNeuron } from 'types/inspection-types/L1VisualNeuron';
import ToolContext from 'App/ToolContext';
import addWidget from 'App/InspectionPanel/L2ArchitectureComponent/LayerComponent/add-widget';
import ModelContext from 'App/ModelContext';
import WidgetContext from 'App/WidgetContext';

interface Props {
    l1Graph: dagre.graphlib.Graph<L1VisualNeuron>;
    previousMGN?: ModelGraphNode;
    currentMGN: ModelGraphNode;
}

const LayerBoundingBox: React.FunctionComponent<Props> = ({ l1Graph: g, previousMGN, currentMGN }: Props) => {
    const previousLayerGraph = new dagre.graphlib.Graph();
    const currentLayerGraph = new dagre.graphlib.Graph();
    g.nodes()
        .map((nId): [string, dagre.Node<L1VisualNeuron>] => [nId, g.node(nId)])
        .forEach(([nId, n]) => {
            const nodeLabel = {
                width: n.width,
                height: n.height,
                x: n.x,
                y: n.y,
            };

            if (previousMGN && n.parentModelGraphNode.id === previousMGN.id) {
                previousLayerGraph.setNode(nId, nodeLabel);
            } else if (n.parentModelGraphNode.id === currentMGN.id) {
                currentLayerGraph.setNode(nId, nodeLabel);
            }
        });

    return (
        <>
            <InteractiveLayerBoundingBox
                currentLayerGraph={currentLayerGraph}
                previousLayerGraph={previousLayerGraph}
                currentMGN={currentMGN}
                previousMGN={previousMGN}
            />
        </>
    );
};

interface InteractiveLayerBoundingBoxProps {
    previousLayerGraph: dagre.graphlib.Graph;
    currentLayerGraph: dagre.graphlib.Graph;
    previousMGN?: ModelGraphNode;
    currentMGN: ModelGraphNode;
}

const InteractiveLayerBoundingBox: React.FunctionComponent<InteractiveLayerBoundingBoxProps> = ({
    previousLayerGraph,
    currentLayerGraph,
    previousMGN,
    currentMGN,
}: InteractiveLayerBoundingBoxProps) => {
    const { model } = useContext(ModelContext);
    const { activeTool } = useContext(ToolContext);
    const { addWidget: addWidgetCb } = useContext(WidgetContext);

    const onClickHandler = useCallback(
        (e: React.MouseEvent, modelGraphNode: ModelGraphNode) => {
            if (activeTool?.isApplicable(modelGraphNode.type)) {
                addWidget(model, modelGraphNode, activeTool, addWidgetCb);
            }
            e.stopPropagation();
        },
        [activeTool, addWidgetCb, model],
    );

    return (
        <>
            {previousMGN && (
                <ConvexHullComponent
                    key={'hull_' + previousMGN.id}
                    entity={previousMGN}
                    graph={previousLayerGraph}
                    onClick={(e) => onClickHandler(e, previousMGN)}
                />
            )}
            <ConvexHullComponent
                key={'hull_' + currentMGN.id}
                entity={currentMGN}
                graph={currentLayerGraph}
                onClick={(e) => onClickHandler(e, currentMGN)}
            />
        </>
    );
};

export default LayerBoundingBox;
