import React, { useCallback, useMemo } from 'react';
import ToolContext, { IToolContext } from 'App/ToolContext';

const ToolContextProvider = ({ children }: { children: React.ReactNode }) => {
    // Lift the value into the parent’s state: https://reactjs.org/docs/context.html#caveats
    const [activeTool, setActiveTool] = React.useState<IToolContext['activeTool']>(null);

    const unsetActiveTool = () => {
        setActiveTool(null);
    };

    // Memoize the callbacks, so they do not lead to unnecessary re-renders.
    const unsetActiveToolMemo = useCallback(unsetActiveTool, []);

    // Memoize the value object itself, so it doesn't lead to unnecessary re-renders.
    const providerValueMemo = useMemo(
        () => ({
            activeTool: activeTool,
            setActiveTool: setActiveTool,
            unsetActiveTool: unsetActiveToolMemo,
        }),
        [activeTool, unsetActiveToolMemo],
    );

    return <ToolContext.Provider value={providerValueMemo}>{children}</ToolContext.Provider>;
};

export default ToolContextProvider;
