import React, { useCallback, useMemo } from 'react';
import ClassSelectionContext from 'App/ClassSelectionContext';
import { produce } from 'immer';

interface Props {
    allClasses: Array<number | string>;
}

const ClassSelectionContextProvider = ({ children, allClasses }: React.PropsWithChildren<Props>) => {
    const [selectedClasses, setSelectedClasses] = React.useState<Array<number | string>>([]);

    const toggleClassSelection = (cls: number | string) => {
        setSelectedClasses((prevSelectedClasses) =>
            produce(prevSelectedClasses, (draftSelectedClasses) => {
                const selectedClassesSet = new Set(draftSelectedClasses);

                if (selectedClassesSet.has(cls)) {
                    selectedClassesSet.delete(cls);
                } else {
                    selectedClassesSet.add(cls);
                }

                return [...selectedClassesSet];
            }),
        );
    };

    const isSelectionEmpty = () => {
        return selectedClasses.length === 0;
    };

    const isClassSelected = (cls: number | string): boolean => {
        if (selectedClasses.length === 0) {
            return true;
        } else {
            return selectedClasses.includes(cls);
        }
    };

    const getSelectedClasses = useCallback(() => {
        if (selectedClasses.length === 0) {
            return allClasses;
        } else {
            return selectedClasses;
        }
    }, [allClasses, selectedClasses]);

    const selectedClassesProcessedMemo = useMemo(() => {
        return [...getSelectedClasses()].sort();
    }, [getSelectedClasses]);

    // Memoize the callbacks, so they do not lead to unnecessary re-renders.
    const isSelectionEmptyMemo = useCallback(isSelectionEmpty, [selectedClasses.length]);
    const toggleClassSelectionMemo = useCallback(toggleClassSelection, []);
    const isClassSelectedMemo = useCallback(isClassSelected, [selectedClasses]);

    // Memoize the value object itself, so it doesn't lead to unnecessary re-renders.
    const providerValueMemo = useMemo(
        () => ({
            selectedClasses: selectedClassesProcessedMemo,
            allClasses,
            isSelectionEmpty: isSelectionEmptyMemo,
            toggleClassSelection: toggleClassSelectionMemo,
            isClassSelected: isClassSelectedMemo,
        }),
        [allClasses, isClassSelectedMemo, isSelectionEmptyMemo, selectedClassesProcessedMemo, toggleClassSelectionMemo],
    );

    return <ClassSelectionContext.Provider value={providerValueMemo}>{children}</ClassSelectionContext.Provider>;
};

export default ClassSelectionContextProvider;
