import React, { useContext, useMemo, useState } from 'react';
import { NeuronConnectionId } from 'types/inspection-types/NeuronConnection';
import { Neuron } from 'types/inspection-types/Neuron';
import { interpolateLab } from 'd3-interpolate';
import NeuronSelectionContext from 'App/InspectionPanel/L1LayerUnitComponent/NeuronSelectionContext';
import tinyColor from 'tinycolor2';
import styled from 'styled-components';
import { useBrush, useLink } from 'tools/hooks/useLinkAndBrush';
import ToolContext from 'App/ToolContext';
import WidgetContext from 'App/WidgetContext';
import addWidget from 'App/InspectionPanel/L1LayerUnitComponent/Neurons/add-widget';

const NeuronLabel = styled.text`
    text-anchor: middle;
    dominant-baseline: central;
    font-size: 10px;
`;

interface Props {
    neuron: Neuron;
    nodeColorScale: (value: number) => string;
    x: number;
    y: number;
    width: number;
    height: number;
}

const NeuronComponent: React.FunctionComponent<Props> = ({ nodeColorScale, neuron: n, x, y, width, height }: Props) => {
    const { selectedNeurons, toggleNeuronSelection, isNeuronSelected } = useContext(NeuronSelectionContext);
    const { activeTool } = useContext(ToolContext);
    const { addWidget: addWidgetCB } = useContext(WidgetContext);

    const [hovered, setHovered] = useState<boolean>(false);

    useBrush<string | undefined>('neuron-hovered', hovered ? n.id : undefined);
    const [isLinked] = useLink<NeuronConnectionId | undefined>(
        'weight-hovered',
        (brushedNeuronConnection) => brushedNeuronConnection?.fromId === n.id || brushedNeuronConnection?.toId === n.id,
    );

    const fill = useMemo(() => {
        let draftFill = nodeColorScale(n.activation);
        if (selectedNeurons.length > 0 && !isNeuronSelected(n.id)) {
            draftFill = interpolateLab(draftFill, 'white')(0.8);
        }
        if (hovered || isLinked) {
            draftFill = '#5C996B';
        }
        return draftFill;
    }, [hovered, isLinked, isNeuronSelected, n.activation, n.id, nodeColorScale, selectedNeurons.length]);

    const onClickHandler = (e: React.MouseEvent<SVGGElement>) => {
        if (activeTool) {
            addWidget(n, activeTool, addWidgetCB);
        } else {
            toggleNeuronSelection(n.id);
        }
        e.stopPropagation();
    };

    return (
        <g
            onClick={onClickHandler}
            onMouseOver={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            transform={`translate(${x} ${y})`}
        >
            <circle
                r={Math.min(width, height) / 2}
                cx={width / 2}
                cy={height / 2}
                style={{ stroke: 'var(--gray)', fill }}
            />
            <NeuronLabel
                fill={tinyColor.mostReadable(fill, [tinyColor('black'), tinyColor('white')]).toHexString()}
                x={width / 2}
                y={height / 2}
            >
                {n.index}
            </NeuronLabel>
        </g>
    );
};

export default NeuronComponent;
