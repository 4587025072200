import React, { FunctionComponent } from 'react';
import { WidgetProps } from 'App/WidgetPanel/Widget';
import _ from 'lodash';
import { scaleLinear } from '@visx/scale';
import { InnerVisContainer, OuterVisContainer } from 'App/WidgetPanel/Widgets/StyledContainers';
import { DataRow, DataValue } from 'types/inspection-types/DataArray';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { Group } from '@visx/group';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { getNumberFormatter } from 'tools/helpers';
import { LinePath } from '@visx/shape';
import { curveBasis } from '@visx/curve';
import { schemeCategory10 } from 'd3-scale-chromatic';

const DEFAULT_MARGIN = { top: 10, right: 10, bottom: 30, left: 35 };

/**
 * Gets the value of an attribute across all steps and all rows.
 * @param stepwiseHistograms an array of steps with each element containing an array of DataRows (i.e., a DataTable)
 * @param attribute the key of the desired attribute
 * @return a flattened array of all values of the desired attribute
 */
const valuesAccessor = (stepwiseHistograms: DataRow[][], attribute: string): DataValue[] =>
    _.flatten(stepwiseHistograms.map((step) => step.map((row) => row[attribute])));

const FeaturewiseDistributionWidget: FunctionComponent<WidgetProps> = ({
    widgetDefinition,
    margin = DEFAULT_MARGIN,
}: WidgetProps) => {
    const entity = widgetDefinition.dataEntities[0];
    const featurewiseHistograms: DataRow[][] = Object.values(_.groupBy(entity.data, 'feature')).sort(
        (g1, g2) => (g1[0]['feature'] as number) - (g2[0]['feature'] as number),
    );

    const maxCount = Math.max(...(valuesAccessor(featurewiseHistograms, 'count') as number[]));
    const minStartBoundary = Math.min(...(valuesAccessor(featurewiseHistograms, 'start') as number[]));
    const maxEndBoundary = Math.max(...(valuesAccessor(featurewiseHistograms, 'end') as number[]));
    const maxAbsBoundary = Math.max(Math.abs(minStartBoundary), Math.abs(maxEndBoundary));

    return (
        <>
            <OuterVisContainer>
                <InnerVisContainer>
                    <ParentSize debounceTime={10}>
                        {({ width: visWidth, height: visHeight }) => {
                            const xExtend = visWidth - margin.left - margin.right;
                            const yExtend = visHeight - margin.top - margin.bottom;

                            const scaleX = scaleLinear({
                                // Make the x-axis span equally in negative and positive direction.
                                domain: [-maxAbsBoundary, maxAbsBoundary],
                                range: [0, xExtend],
                            });

                            const scaleY = scaleLinear({
                                domain: [0, maxCount],
                                range: [yExtend, 0],
                            });

                            return (
                                <>
                                    <svg width={visWidth} height={visHeight} style={{ background: '#fff' }}>
                                        <Group left={margin.left} top={margin.top}>
                                            <AxisLeft scale={scaleY} numTicks={0} left={scaleX(0)} />
                                            <AxisLeft tickFormat={getNumberFormatter(3)} scale={scaleY} numTicks={5} />
                                            <AxisBottom
                                                tickFormat={getNumberFormatter(3)}
                                                top={yExtend}
                                                scale={scaleX}
                                                numTicks={5}
                                            />
                                            {featurewiseHistograms.map((data, featureIdx) => {
                                                const color = schemeCategory10[featureIdx];

                                                return (
                                                    <LinePath
                                                        key={featureIdx}
                                                        data={data}
                                                        curve={curveBasis}
                                                        y={(d) => scaleY(d['count'] as number) ?? 0}
                                                        x={(d) =>
                                                            scaleX(
                                                                ((d['start'] as number) + (d['end'] as number)) / 2,
                                                            ) ?? 0
                                                        }
                                                        stroke={color}
                                                        strokeWidth={2}
                                                    />
                                                );
                                            })}
                                        </Group>
                                    </svg>
                                </>
                            );
                        }}
                    </ParentSize>
                </InnerVisContainer>
            </OuterVisContainer>
        </>
    );
};

export default React.memo(FeaturewiseDistributionWidget);
