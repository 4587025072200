import {
    IoArrowDownCircleOutline,
    IoArrowUpCircleOutline,
    IoBarChartOutline,
    IoCheckmarkCircleOutline,
    IoCloseCircleOutline,
    IoCodeSlashOutline,
    IoCreateOutline,
    IoDocumentOutline,
    IoEnterOutline,
    IoGitBranch,
    IoGlassesOutline,
    IoHardwareChipOutline,
    IoImagesOutline,
    IoPlayCircleOutline,
    IoSearchOutline,
    IoStatsChart,
    IoTextOutline,
    IoTimerOutline,
} from 'react-icons/io5';
import { MdCompare, MdGradient, MdMultilineChart, MdStorage } from 'react-icons/md';
import { AiOutlineDotChart } from 'react-icons/ai';
import { BsGrid3X3GapFill } from 'react-icons/bs';
import { FiDatabase } from 'react-icons/fi';
import { RiBarChartHorizontalFill, RiTimerLine } from 'react-icons/ri';
import React from 'react';
import { LevelOfAbstraction } from 'types/inspection-types/LevelOfAbstraction';
import { EntityCategory, EntityType, getEntityCategory } from 'types/inspection-types/EntityType';

interface ITool {
    id: string;
    name: string;
    description?: string | JSX.Element;
    icon: JSX.Element;
    group: string;
    applicableOn: LevelOfAbstraction[];
    applicableTo: EntityType[] | ((entityType: EntityType) => boolean);
}

// This is needed for runtime typechecks to enable iterating over interface keys (for type-guard)
const ITOOL_INSTANCE = {
    id: '',
    name: '',
    icon: <></>,
    group: '',
    applicableOn: [LevelOfAbstraction.MULTI_MODEL],
    applicableTo: [],
};

// Type-guard for the ModelGraphNode type
function isTool(entity: unknown): entity is Tool {
    let result = true;

    Object.keys(ITOOL_INSTANCE).forEach((key: string) => {
        result = result && (entity as any)[key] !== undefined; // eslint-disable-line @typescript-eslint/no-explicit-any
    });

    return result;
}

export class Tool implements ITool {
    public static readonly RUNTIME_STATISTICS = new Tool(
        'c49992a3-a5e2-4f69-ad04-1b26b55ed9da',
        'Runtime Statistics',
        <IoTimerOutline />,
        'Metrics',
        [LevelOfAbstraction.MULTI_MODEL],
        [EntityType.TREE_OF_MODELS],
        'The execution time needed to predict the outputs for all samples in the test set.',
    );
    public static readonly PERFORMANCE_METRICS = new Tool(
        '3ddd8345-7623-4f81-a0f2-42e629ff1ca3',
        'Performance Metrics',
        <RiTimerLine />,
        'Metrics',
        [LevelOfAbstraction.MULTI_MODEL, LevelOfAbstraction.SINGLE_MODEL],
        (entityType: EntityType) =>
            getEntityCategory(entityType) === EntityCategory.TREE_OF_MODELS ||
            getEntityCategory(entityType) === EntityCategory.MODEL,
        (
            <>
                Shows performance metrics (e.g., <b>accuracy</b> or <b>loss</b>) during training for single models, or
                the performance at the last checkpoint for a group of models.
            </>
        ),
    );

    public static readonly MODEL_SAVE_SIZE = new Tool(
        '0643c342-965d-4cb7-870c-716a50886690',
        'Model Save Size',
        <FiDatabase />,
        'Memory',
        [LevelOfAbstraction.MULTI_MODEL],
        [EntityType.TREE_OF_MODELS],
        'Shows the size of the saved model. This includes only weights and architecture, i.e., what is needed to fully restore the trained model instance.',
    );
    public static readonly CHECKPOINT_SIZE = new Tool(
        '73a28e51-dcef-42be-bceb-40070f8bdd2c',
        'Checkpoint Size',
        <MdStorage />,
        'Memory',
        [LevelOfAbstraction.MULTI_MODEL, LevelOfAbstraction.SINGLE_MODEL],
        (entityType: EntityType) =>
            getEntityCategory(entityType) === EntityCategory.TREE_OF_MODELS ||
            getEntityCategory(entityType) === EntityCategory.MODEL,
        (
            <>
                Shows the size of the iNNspector checkpoints during training (for single models), or the size of the
                final checkpoint (for a group of models).
                <br />
                <span style={{ color: 'var(--danger)' }}>
                    This includes all data, including activations and dataset samples. For the bare model save size
                    refer to the &quot;Model Save Size&quot; tool.
                </span>
            </>
        ),
    );
    public static readonly RAM_SIZE = new Tool(
        '84d58a5a-6320-42e1-9e95-5fd490a9e654',
        'RAM Utilization',
        <IoHardwareChipOutline />,
        'Memory',
        [LevelOfAbstraction.MULTI_MODEL],
        [EntityType.TREE_OF_MODELS],
        'The amount of RAM needed to execute the model.',
    );

    public static readonly SHOW_CODE = new Tool(
        '7348b9d4-f282-4380-99a7-5873368b2388',
        'Show Code',
        <IoCodeSlashOutline />,
        'Code',
        [LevelOfAbstraction.MULTI_MODEL, LevelOfAbstraction.SINGLE_MODEL],
        undefined,
        'Shows different sections of the source file in which the Keras model was defined.',
    );
    public static readonly BRANCH_MODEL = new Tool(
        'c33fa264-6b48-4cbc-be4a-8a6b0b9e4efa',
        'Branch Model',
        <IoGitBranch />,
        'Code',
        [LevelOfAbstraction.MULTI_MODEL, LevelOfAbstraction.SINGLE_MODEL],
        (entityType: EntityType) => getEntityCategory(entityType) === EntityCategory.MODEL,
        'Creates a new iNNspector header, indicating a parent-child relationship of the selected model.',
    );

    public static readonly MODEL_INFO_LENS = new Tool(
        'fc588827-a95c-42c1-9fbf-ffbbbd79251c',
        'Model Info',
        <IoDocumentOutline />,
        'Statistics',
        [LevelOfAbstraction.MULTI_MODEL],
        (entityType: EntityType) =>
            getEntityCategory(entityType) === EntityCategory.TREE_OF_MODELS ||
            getEntityCategory(entityType) === EntityCategory.MODEL,
        'Shows general informations about the model, such as name, creation date, or number of layers.',
    );

    public static readonly ARCHITECTURE_LENS = new Tool(
        'c5bb9cb7-732f-45f8-960d-2da233cc296e',
        'Architecture Lens',
        <IoGlassesOutline />,
        'Lenses',
        [LevelOfAbstraction.MULTI_MODEL],
        undefined,
        'Look into the L2 architecture representation by hovering the model.',
    );
    public static readonly LAYER_LENS = new Tool(
        '0504d316-0b88-494e-bef6-942988ff4a52',
        'Layer Lens',
        <IoSearchOutline />,
        'Lenses',
        [LevelOfAbstraction.MULTI_MODEL],
        (entityType: EntityType) => getEntityCategory(entityType) === EntityCategory.LAYER,
        'Look into the L2 representation of single layers by hovering.',
    );

    public static readonly CLASSIFIER_SAMPLES = new Tool(
        '3c9b3bd2-d10e-4c62-9f4b-b1869766478c',
        'Input/Classification',
        <IoPlayCircleOutline />,
        'Classifier',
        [LevelOfAbstraction.MULTI_MODEL, LevelOfAbstraction.SINGLE_MODEL],
        [EntityType.MODEL_CLASSIFIER],
        (
            <>
                Shows <b>input</b> and <b>classifier output</b> side-by-side for a subset of the dataset.
            </>
        ),
    );
    public static readonly CLASSIFIER_CORRECTLY_CLASSIFIED = new Tool(
        '9c28f987-d870-488c-839c-6977df892b0a',
        'Correctly Classified',
        <IoCheckmarkCircleOutline />,
        'Classifier',
        [LevelOfAbstraction.MULTI_MODEL, LevelOfAbstraction.SINGLE_MODEL],
        [EntityType.MODEL_CLASSIFIER],
        (
            <>
                Shows <b>input</b> and <b>classifier output</b> side-by-side for a subset of <b>correctly</b> classified
                samples.
            </>
        ),
    );
    public static readonly CLASSIFIER_WRONGLY_CLASSIFIED = new Tool(
        'f75fb36a-cc80-4605-927e-3a578acbeb40',
        'Wrongly Classified',
        <IoCloseCircleOutline />,
        'Classifier',
        [LevelOfAbstraction.MULTI_MODEL, LevelOfAbstraction.SINGLE_MODEL],
        [EntityType.MODEL_CLASSIFIER],
        (
            <>
                Shows <b>input</b> and <b>classifier output</b> side-by-side for a subset of <b>wrongly</b> classified
                samples.
            </>
        ),
    );
    public static readonly CLASSIFIER_CONFUSION_MATRIX = new Tool(
        '8fc1e128-1d88-4b53-bd9c-27383bc45cd2',
        'Confusion Matrix',
        <BsGrid3X3GapFill />,
        'Classifier',
        [LevelOfAbstraction.MULTI_MODEL, LevelOfAbstraction.SINGLE_MODEL],
        [EntityType.MODEL_CLASSIFIER],
        (
            <>
                Plots the <b>true class labels</b> <b>vs.</b> the <b>predicted class labels</b> in matrix.
            </>
        ),
    );

    public static readonly AUTOENCODER_SAMPLES = new Tool(
        '2e068a9f-8258-4d10-8d8d-c47003d1e0fa',
        'Input/Reconstruction',
        <MdCompare />,
        'Auto-Encoder',
        [LevelOfAbstraction.MULTI_MODEL, LevelOfAbstraction.SINGLE_MODEL],
        [EntityType.MODEL_AUTOENCODER],
        (
            <>
                Shows <b>input</b> and <b>reconstruction</b> side-by-side for a subset of the dataset.
            </>
        ),
    );

    public static readonly MEAN_CLASS_INPUT = new Tool(
        'bc70915c-b109-425c-9be4-d2c9a54e8b28',
        'Mean Class Input',
        <IoImagesOutline />,
        'Classes',
        [LevelOfAbstraction.MULTI_MODEL],
        undefined,
        'Shows the mean input for a dataset class.',
    );
    public static readonly CLASS_OUTPUT_DISTRIBUTION = new Tool(
        '84057bfe-9c6e-4f49-8700-cdf4f500c16e',
        'Class Output Distribution',
        <IoEnterOutline />,
        'Classes',
        [LevelOfAbstraction.MULTI_MODEL],
        undefined,
        'Shows the mean output for a dataset class.',
    );
    public static readonly CLASS_OUTPUT_CERTAINTY = new Tool(
        '0554504d-9a93-4988-a395-42018e66b76d',
        'Class Certainty',
        <IoBarChartOutline />,
        'Classes',
        [LevelOfAbstraction.MULTI_MODEL],
        undefined,
        'Show the average certainty for each output class.',
    );

    public static readonly NOTE = new Tool(
        '4ed0db17-c4d7-4846-9f5b-0704e491ab16',
        'Note',
        <IoCreateOutline />,
        'Annotations',
        [LevelOfAbstraction.MULTI_MODEL, LevelOfAbstraction.SINGLE_MODEL],
        (entityType: EntityType) =>
            getEntityCategory(entityType) === EntityCategory.TREE_OF_MODELS ||
            getEntityCategory(entityType) === EntityCategory.MODEL ||
            getEntityCategory(entityType) === EntityCategory.LAYER,
        'Annotate entities and widgets.',
    );
    public static readonly SUMMARY = new Tool(
        '5b09eca1-baa7-4049-bc3d-670fa6c0fb08',
        'Summary',
        <IoTextOutline />,
        'Annotations',
        [LevelOfAbstraction.MULTI_MODEL],
        undefined,
        'Add entities, notes, or widgets to the summary.',
    );

    public static readonly PROJECTION_2D_SCATTERPLOT = new Tool(
        '99ed9d05-ed12-4f4a-a908-8444d8cd86fb',
        'Scatterplot',
        <AiOutlineDotChart />,
        'Data Distribution',
        [LevelOfAbstraction.SINGLE_MODEL, LevelOfAbstraction.LAYERS_UNITS],
        (entityType: EntityType) => getEntityCategory(entityType) === EntityCategory.LAYER,
        'Scatterplot of n-dimensional data points projected to two dimensions.',
    );
    public static readonly DISTRIBUTION_MULTI_HISTOGRAM = new Tool(
        'fe8fae24-c70b-4a08-a59f-bf060ef6be81',
        'Multi-Histogram',
        <RiBarChartHorizontalFill />,
        'Data Distribution',
        [LevelOfAbstraction.SINGLE_MODEL, LevelOfAbstraction.LAYERS_UNITS],
        (entityType: EntityType) => getEntityCategory(entityType) === EntityCategory.LAYER,
        'Histogram over all checkpoints.',
    );
    public static readonly DISTRIBUTION_HISTOGRAM = new Tool(
        'b5f2bf47-28aa-4842-a951-b5c31f736136',
        'Histogram',
        <IoStatsChart />,
        'Data Distribution',
        [LevelOfAbstraction.SINGLE_MODEL, LevelOfAbstraction.LAYERS_UNITS],
        (entityType: EntityType) => getEntityCategory(entityType) === EntityCategory.LAYER,
        'Histogram over last checkpoint.',
    );
    public static readonly DISTRIBUTION_FEATURE_HISTOGRAM = new Tool(
        '87b2a1be-6ce8-427c-b51d-17292cf4686b',
        'Feature Distribution Plot',
        <MdMultilineChart />,
        'Data Distribution',
        [LevelOfAbstraction.SINGLE_MODEL, LevelOfAbstraction.LAYERS_UNITS],
        (entityType: EntityType) => getEntityCategory(entityType) === EntityCategory.LAYER,
        'Density estimation over the distribution of each output feature. Works only for output dimensionality \u2266 10.',
    );

    public static readonly MINIMIZING_SAMPLES = new Tool(
        '5ce79e17-60dc-4c4f-a2bd-c359b19ee428',
        'Minimizing Samples',
        <IoArrowDownCircleOutline />,
        'Samples',
        [LevelOfAbstraction.LAYERS_UNITS],
        [EntityType.SINGLE_NEURON],
        'Show input samples that minimize this nodes activation.',
    );
    public static readonly MAXIMIZING_SAMPLES = new Tool(
        'a6abcae3-bdd6-4542-af77-85c563f94dc0',
        'Maximizing Samples',
        <IoArrowUpCircleOutline />,
        'Samples',
        [LevelOfAbstraction.LAYERS_UNITS],
        [EntityType.SINGLE_NEURON],
        'Show input samples that maximize this nodes activation.',
    );
    public static readonly DEEP_DREAM = new Tool(
        '0d55a3c4-9b83-4d66-a314-eb15de06ee79',
        'Deep Dream',
        <MdGradient />,
        'Deep Dream',
        [LevelOfAbstraction.LAYERS_UNITS],
        undefined,
        'Apply deep dream to this node, i.e., generate a plausible input image that maximizes the activation in this node.',
    );

    // private to disallow creating other instances of this type
    private constructor(
        public readonly id: ITool['id'],
        public readonly name: ITool['name'],
        public readonly icon: ITool['icon'],
        public readonly group: ITool['group'],
        public readonly applicableOn: ITool['applicableOn'],
        public readonly applicableTo: ITool['applicableTo'] = [],
        public readonly description: ITool['description'] = undefined,
    ) {}

    public isApplicable(entityType: EntityType): boolean {
        if (typeof this.applicableTo === 'function') {
            return this.applicableTo(entityType);
        } else {
            return this.applicableTo.includes(entityType);
        }
    }

    public static getTools(lofa?: LevelOfAbstraction): Tool[] {
        const allTools = Object.values(Tool).filter(isTool);

        if (lofa) return allTools.filter((t) => t.applicableOn.includes(lofa));

        return allTools;
    }

    public static getToolGroups(lofa?: LevelOfAbstraction): Record<string, Tool[]> {
        const tools = Tool.getTools(lofa);

        return tools.reduce(
            (toolGroups, tool) => {
                if (!toolGroups[tool.group]) {
                    toolGroups[tool.group] = [];
                }

                toolGroups[tool.group].push(tool);

                return toolGroups;
            },
            {} as Record<string, Tool[]>,
        );
    }
}
