import React, { memo } from 'react';
import { WidgetProps } from 'App/WidgetPanel/Widget';
import { InnerVisContainer, OuterVisContainer } from 'App/WidgetPanel/Widgets/StyledContainers';
import styled from 'styled-components';
import ClassSelectionContext from 'App/ClassSelectionContext';
import { isNumber, isString } from 'types/inspection-types/DataArray';

const WrapperDiv = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const SampleContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: start;
`;

const StyledImg = styled.img`
    margin: 5px;
`;

const ImageDataSampleWidget = ({ widgetDefinition }: WidgetProps) => {
    const { isClassSelected } = React.useContext(ClassSelectionContext);

    let { data } = widgetDefinition.dataEntities[0];

    const isClassifier =
        (data[0] !== undefined && isNumber(data[0]['yPrediction'])) || isString(data[0]['yPrediction']);
    data = data.filter((d) => isClassSelected(d['yLabel'] as number | string));

    return (
        <>
            <OuterVisContainer>
                <InnerVisContainer>
                    <WrapperDiv>
                        {data.map((d, idx) => {
                            const mark = isClassifier && d['yPrediction'] !== d['yLabel'];

                            return (
                                <SampleContainerDiv key={idx}>
                                    <StyledImg src={d['xImage'] as string} alt={d['yLabel'] as string} />
                                    <span style={{ color: mark ? 'var(--danger)' : 'var(--dark)' }}>
                                        {isClassifier && (d['yPrediction'] as string | number)} [
                                        {d['yLabel'] as string | number}]
                                    </span>
                                </SampleContainerDiv>
                            );
                        })}
                    </WrapperDiv>
                </InnerVisContainer>
            </OuterVisContainer>
        </>
    );
};

export default memo(ImageDataSampleWidget);
