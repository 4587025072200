import { useSessionStorage } from 'react-use';
import { useCallback, useEffect } from 'react';
import { off, on } from 'react-use/lib/misc/util';

export const useFirstLoad = () => {
    const [isFirstLoad, setIsFirstLoad] = useSessionStorage('first-load', true);

    const onUnloadHandler = useCallback(() => {
        setIsFirstLoad(false);
    }, [setIsFirstLoad]);

    useEffect(() => {
        on(window, 'beforeunload', onUnloadHandler);
        return () => off(window, 'beforeunload', onUnloadHandler);
    }, [onUnloadHandler]);

    return isFirstLoad;
};
