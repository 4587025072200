import React, { useCallback, useContext } from 'react';
import InterestingnessContext from 'App/InterestingnessContext';
import GroupsContext from 'App/GroupsContext';
import { InterestingnessType } from 'types/inspection-types/InterestingnessType';
import _ from 'lodash';
import { CenteredCardBody, RotatedText, StyledCard } from 'App/SettingsPanel/styled';
import { Form } from 'react-bootstrap';

const useInterestingnessTypes = () => {
    const { groups } = useContext(GroupsContext);

    return React.useMemo((): InterestingnessType[] => {
        const interestingnessTypes: InterestingnessType[] = [];

        groups.forEach((group) => {
            interestingnessTypes.push(...InterestingnessType.fromDescriptors(group.interestingness));

            group.models.forEach((model) => {
                interestingnessTypes.push(...InterestingnessType.fromDescriptors(model.interestingness));

                model.graph.nodes.forEach((modelGraphNode) => {
                    interestingnessTypes.push(...InterestingnessType.fromDescriptors(modelGraphNode.interestingness));

                    modelGraphNode.innardGraph.nodes.forEach((layerGraphNode) => {
                        interestingnessTypes.push(
                            ...InterestingnessType.fromDescriptors(layerGraphNode.interestingness),
                        );
                    });
                });
            });
        });

        return InterestingnessType.unique(interestingnessTypes);
    }, [groups]);
};

const useInterestingnessTypeCheckbox = (): [
    (interestingness: InterestingnessType) => boolean,
    (interestingnessType: InterestingnessType) => void,
] => {
    const { isInterestingnessActive, toggleInterestingness } = useContext(InterestingnessContext);

    const onChangeHandler = useCallback(
        (interestingness: InterestingnessType) => {
            toggleInterestingness(interestingness);
        },
        [toggleInterestingness],
    );

    return [isInterestingnessActive, onChangeHandler];
};

interface Props {}

const InterestingnessSettings: React.FunctionComponent<Props> = (props: Props) => {
    const [isInterestingnessActive, onChangeHandler] = useInterestingnessTypeCheckbox();
    const availableInterestingnessTypes = useInterestingnessTypes();

    const interestingnessTypeGroups = _.groupBy(availableInterestingnessTypes, (ait) => ait.supercategory);

    return (
        <>
            {Object.entries(interestingnessTypeGroups).map(([supercategory, interestingnessTypes]) => {
                return (
                    <StyledCard key={supercategory}>
                        <RotatedText>{supercategory}</RotatedText>
                        <CenteredCardBody>
                            <Form>
                                {interestingnessTypes.map((it: InterestingnessType) => {
                                    const itemId = it.supercategory + '/' + it.subcategory;
                                    return (
                                        <Form.Check
                                            checked={isInterestingnessActive(it)}
                                            key={itemId}
                                            label={it.subcategory}
                                            type={'checkbox'}
                                            id={itemId}
                                            onChange={() => onChangeHandler(it)}
                                        />
                                    );
                                })}
                            </Form>
                        </CenteredCardBody>
                    </StyledCard>
                );
            })}
        </>
    );
};

export default InterestingnessSettings;
