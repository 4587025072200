import dagre from 'dagre';
import { DagreLayerInnardsNode } from 'types/dagre-nodes/DagreLayerInnardsNode';
import { LayerGraph } from 'types/nn-types/LayerGraph';

export function layout(
    layerGraph: LayerGraph,
    layerInnardDimensions: Record<string, { width: number; height: number }>,
    x: number,
    y: number,
    paddingX = 50,
    paddingY = 100,
): dagre.graphlib.Graph<DagreLayerInnardsNode> {
    const g = new dagre.graphlib.Graph<DagreLayerInnardsNode>({ compound: true })
        .setGraph({
            rankdir: 'LR',
            nodesep: 20,
            edgesep: 20,
            ranksep: 40,
            marginx: paddingX,
            marginy: paddingY,
        })
        .setDefaultEdgeLabel(() => ({}));

    // Add nodes to graph
    layerGraph.nodes.forEach((n) => {
        const mSize = layerInnardDimensions[n.id];

        g.setNode(n.id, {
            layerGraphNode: n,
            label: n.name,
            width: mSize ? mSize.width : 0,
            height: mSize ? mSize.height : 0,
        });
    });

    // Add edges to graph
    layerGraph.links.forEach((l) => {
        g.setEdge(l.source, l.target);
    });

    // Layout nodes
    dagre.layout(g);

    return g;
}
