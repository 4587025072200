import React, { FunctionComponent } from 'react';
import Joyride, { Step } from 'react-joyride';
import { CgDebug } from 'react-icons/cg';
import { useFirstLoad } from 'tools/hooks/useFirstLoad';
import { BiTask } from 'react-icons/bi';
import { AiOutlineTool } from 'react-icons/ai';
import { FaHandPointDown, FaHighlighter, FaMousePointer } from 'react-icons/fa';
import { BsLayers } from 'react-icons/bs';

interface Props {}

const JOYRIDE_STEPS: Step[] = [
    {
        content: (
            <>
                <h2>Welcome to iNNspector!</h2>
                The system for systematic model debugging&nbsp;
                <CgDebug />
            </>
        ),
        locale: { skip: <strong aria-label="skip">Skip Tutorial</strong> },
        placement: 'center',
        target: 'body',
    },
    {
        content: (
            <>
                <h2>Use-Cases</h2>
                Click here to load a use-case from the paper&nbsp;
                <BiTask />
            </>
        ),
        target: '#nav-use-case-dropdown',
    },
    {
        content: (
            <>
                <h2>Toolbox</h2>
                Apply tools from the toolbox to model groups,
                <br />
                models, layers, or variables&nbsp;
                <AiOutlineTool />
            </>
        ),

        placement: 'left',
        target: '#toolbox-panel',
    },
    {
        content: (
            <>
                <h2>Highlight Entity Types</h2>
                Choose entity types of interest
                <br />
                for the analysis task&nbsp;
                <FaHighlighter />
            </>
        ),

        placement: 'right',
        target: '#settings-panel',
    },
    {
        content: (
            <>
                <h2>Locate &#8660; Interestingness</h2>
                Toggle between <b>Locate</b> and
                <br />
                <b>Interestingness</b> panels&nbsp;
                <FaHandPointDown />
            </>
        ),
        placement: 'bottom',
        target: '#settings-panel-next-icon',
    },
    {
        content: (
            <>
                <h2>Structural Entity</h2>
                Apply tools&nbsp;
                <AiOutlineTool />,
                <br />
                Double-click to descend to lower
                <br />
                levels of abstraction&nbsp;
                <BsLayers />,
                <br />
                or [Ctrl] + click to select entity&nbsp;
                <FaMousePointer />
            </>
        ),
        placement: 'right',
        target: '#l3-model-component-0',
    },
];

const JoyrideOnboarding: FunctionComponent<Props> = ({}: Props) => {
    const isFirstLoad = useFirstLoad();

    return (
        <>
            <Joyride
                continuous
                styles={{
                    options: {
                        zIndex: 10000,
                    },
                }}
                locale={{
                    last: 'Start Exploring!',
                }}
                steps={JOYRIDE_STEPS}
                run={isFirstLoad}
            />
        </>
    );
};

export default JoyrideOnboarding;
