import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import SelectionContext from 'App/SelectionContext';
import { darker, lightest, mid } from 'tools/colors';
import ToolContext from 'App/ToolContext';
import ModelContext from 'App/ModelContext';
import { useInterestingnessSVGFilter, useSVGFilter } from 'tools/hooks/useSVGFilter';
import InterestingnessContext from 'App/InterestingnessContext';
import ApplicabilityHint from 'App/InspectionPanel/ApplicabilityHint';
import GroupContext from 'App/GroupContext';
import { useBrush, useLink } from 'tools/hooks/useLinkAndBrush';

const ModelBoundingRect = styled.rect<{ $selected: boolean; $baseColor: string }>`
    stroke-width: ${({ $selected }) => ($selected ? '3px' : '1px')};
    stroke: ${({ $baseColor }) => mid($baseColor)};
    fill: ${({ $baseColor }) => lightest($baseColor)};
`;

interface Props {
    id?: string;
    width: number;
    height: number;
    hovered: boolean;
}

const ModelBoundingBox = (props: Props) => {
    const { group } = useContext(GroupContext);
    const { model } = useContext(ModelContext);
    const { activeTool } = React.useContext(ToolContext);
    const { isSelected } = React.useContext(SelectionContext);
    const { getInterestingness, getMaxInnerInterestingness } = useContext(InterestingnessContext);

    useBrush<string | undefined>('entity-hovered', props.hovered ? model.id : undefined);
    const [isLinked] = useLink<string | undefined>('widget-hovered', (brushedEntityIds) => {
        return brushedEntityIds ? brushedEntityIds.includes(model.id) : false;
    });

    const selected = isSelected(model);

    const maxInterestingness = getMaxInnerInterestingness(group);
    const modelInterestingness = getInterestingness(model);
    let interestingness;
    if (maxInterestingness && modelInterestingness) {
        interestingness = modelInterestingness / maxInterestingness;
    }

    const [filterId, filterElement] = useSVGFilter(
        <feDropShadow dx="0" dy="0" stdDeviation="10" floodColor={darker(model.preferences.baseColor)} />,
    );

    const [interestingnessFilterId, interestingnessFilterElement] = useInterestingnessSVGFilter(interestingness ?? 0);

    return (
        <g>
            {filterElement}
            {interestingnessFilterElement}
            {/* This ModelBoundingRect is just so that transparent filters of the actual one
                do not reveal the edge structure behind. */}
            {activeTool?.isApplicable(model.type) && <ApplicabilityHint x={props.width / 2} y={0} size={16} />}
            <ModelBoundingRect
                rx="5"
                x={0}
                y={0}
                width={props.width}
                height={props.height}
                $selected={false}
                $baseColor={'white'}
            />
            <ModelBoundingRect
                id={props.id}
                className={activeTool?.isApplicable(model.type) || isLinked ? 'clickable-svg-element' : ''}
                filter={
                    isLinked ? `url(#${filterId})` : interestingness ? `url(#${interestingnessFilterId})` : undefined
                }
                rx="5"
                x={0}
                y={0}
                width={props.width}
                height={props.height}
                $selected={selected}
                $baseColor={model.preferences.baseColor}
            />
        </g>
    );
};

export default ModelBoundingBox;
