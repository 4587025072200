import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Model } from 'types/nn-types/Model';
import { StyledModelLabel } from 'App/InspectionPanel/L3TreeComponent/ModelComponent/styled';
import { toast } from 'react-toastify';

const ModelIdLabel = styled(StyledModelLabel)`
    dominant-baseline: text-after-edge;
    cursor: pointer;
`;

interface Props {
    x: number;
    y: number;
    model: Model;
}

const ModelInfoLabel: React.FunctionComponent<Props> = ({ x, y, model }: Props) => {
    const onModelIdClickHandler = useCallback(
        (e: React.MouseEvent) => {
            navigator.clipboard.writeText(model.id).then(() => {
                toast('Model ID copied to clipboard.', {
                    type: 'info',
                });
            });
        },
        [model.id],
    );

    return (
        <>
            <ModelIdLabel x={x} y={y} onClick={onModelIdClickHandler}>
                {model.id}
            </ModelIdLabel>
        </>
    );
};

export default ModelInfoLabel;
